@import "../../assets/global.scss";

.home-img-container {
    height:100%;
    width:100%;
    margin-top: 0;
    .mask {
        width:100%;
        height:100vh;
        position:relative;
        background-color: $maindark;
        opacity: 0.4;
        .main-img {
            width:100%;
            height:100%;
            background-size: cover;
            background-position: center;
            object-fit: cover;
        }
    }
    .content {
        position: absolute;
        transform: translate(-50%,-50%);
        top:50%;
        left:50%;
        text-align: center;
        color:$mainlight;
        h1 {
            font-size: 4rem;
            padding:0.6rem 0 1.5rem;
        }
        p {
            font-size: 1.4rem;
            font-weight: 300;
            text-transform: uppercase;
        }
        .btn {
            padding:12px 32px;
            margin: 3%;
            font-size:1rem;
            text-transform: uppercase;
            color:$mainlight;
            border:1px solid $mainlight;
            font-weight: 600;
            cursor:pointer;
            border-radius: 5px;
          }
        
          .btn:hover {
            background-color: $accentcolor;
            color:$maindark;
            border: 1px solid $maindark;
            transition: 0.4s;
          }
          .socials {
            margin:3%;
            color:$mainlight;
            position: relative;
          }
          .socials:hover {
            color:$accentcolor;
          }
          .socials:active {
            color:$mainlight;
          }
    }
}

@media screen and (max-width:640px) {
    .home-img-container{
        .content{
            h1{
                font-size: 3rem;
            }
            p{
                font-size: 1.4rem;
            }
            .socials{
                transform: translateX(5px);
            }
        }
    }
}