@import "../../assets/global.scss";

.project-heading {
    text-align: center;
    padding:4rem 0 2rem 0;
}

.project-container {
    max-width: 1140px;
    margin:auto;
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap:10px;
    padding-bottom:5rem;
    justify-content: center;
    .projectcard{
        background: $accentdark;
        padding:1rem 0.8rem;
        margin:auto;
        margin-top:10px;
        min-width: 21rem;
        min-height: 600px;
        .project-title, p {
            color:#fff;
            text-align: left;
            padding:1.2rem 0;
        }
        .desc{
            min-width: 80%;
        }
        img{
            width:100%;
        }
        .project-btns{
            display:flex;
            justify-content: space-between;
            .proj-btn {
                padding:12px 32px;
                width:80%;
                margin: 1rem 0.5rem auto;
                background-color: $mainlight;
                font-size:1rem;
                text-transform: uppercase;
                color:$maindark;
                border:1px solid $mainlight;
                font-weight: 600;
                cursor:pointer;
                border-radius: 5px;
                text-align:center;
              }
              .proj-btn-null {
                padding:12px 32px;
                width:80%;
                margin: 1rem 0.5rem auto;
                background-color: $accentdark;
                font-size:1rem;
                text-transform: uppercase;
                color:$accentdark;
                border:1px solid $accentdark;
                font-weight: 600;
                border-radius: 5px;
                text-align:center;
              }
            
              .proj-btn:hover {
                background-color: $accentcolor;
                color:$maindark;
                border: 1px solid $maindark;
                transition: 0.4s;
              }

              .proj-btn-null:hover {
                cursor:default;
              }

        }
    }
}

@media screen and (max-width:740px) {
    .project-container{
        max-width:95%;
        margin:auto;
        grid-template-columns: 1fr;
    }
}
