@import "../../assets/global.scss";

.form-container {
    color:#fff;
    max-width: 1140px;
    margin:auto;
    form {
        display:flex;
        flex-direction: column;
        padding:1.5rem;
        margin: auto;
        min-width: 200px;
        max-width:600px;

        label {
            font-size: 1.2rem;
        }

        label, input, textarea {
            margin-bottom: 10px;
        }

        input, textarea {
            padding:10px 18px;
            font-size:1.2rem;
            background-color: $accentdark;
            color:$accentcolor;
        }
    }
    .button {
        padding:12px 32px;
        width:50%;
        margin: 20px auto;
        background-color: $mainlight;
        font-size:1rem;
        text-transform: uppercase;
        color:$maindark;
        border:1px solid $mainlight;
        font-weight: 600;
        cursor:pointer;
        border-radius: 5px;
        text-align:center;
    }
    .button:hover {
        background-color: $accentcolor;
        color:$maindark;
        border: 1px solid $maindark;
        transition: 0.4s;
      }
}

@media screen and (max-width:740px) {
    .form-container{
        form {
            display:flex;
            flex-direction: column;
            padding:2rem 1rem;
            margin: auto;
            min-width: 80%;
            max-width:90%;
        }
        form label, form textarea, form input {
            margin-bottom: 1rem;
        }
    }
}