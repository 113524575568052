@import "../../assets/global.scss";

.img-container{
    width:100%;
    height:30vh;
    position:relative;
    color:$mainlight;
    .heading{
        width:100%;
        height:100%;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    h1 {
        font-size: 2.4rem;
    }
    p {
        margin: 14px 30px 0;
        font-size: 1rem;
        text-align: center;
    }
}

.main-img {
    content:"";
    background: url("https://images.unsplash.com/photo-1511376777868-611b54f68947?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80");
    background-size: cover;
    background-position: center top;
    height:100%;
    width: 100%;
    position:absolute;
    top:0;
    left:0;
    z-index:1;
}

@media screen and (max-width: 640px) {
    .img-container{
        h1{
            font-size: 2rem;
        }
    }
    
}