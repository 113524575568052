@import './assets/global.scss';

* {
  box-sizing: border-box;
  padding: 0;
  margin:0;
  text-decoration: none !important;
  -ms-overflow-style: none;
}

body {
  font-family: $fonts;
  background-color: black;
  overflow:hidden;
  ul {
    list-style-type: none;
  }
}

body::-webkit-scrollbar{
  display: none;
}
